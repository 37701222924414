import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import _get from "lodash/get";

import Layout from "../../components/Layout";
import Mainpitch from "./mainpitch/Mainpitch";
import DigitalTransformation from "../../components/digitalTransformationSection/DigitalTransformation";
import CompetenceCenter from "./competenceCenter/CompetenceCenter";
import CaseStudyExpanded from "./caseHistoryExpanded/caseStudyExpanded";
import CaseHistory from "./caseHistory/CaseHistory";
import Livion from "./livion/Livion";

import "./home.scss";
import ContactFormComponent from "../../components/ContactFormComponent/ContactFormComponent";
import SEO from "../../components/Seo";

export const IndexPageTemplate = ({
  mainSections,
  mainpitch,
  livion,
}) => (
  <>
    <div className="home">
      <Mainpitch text={mainpitch.text} />
      <DigitalTransformation mainSections={mainSections} />
      <CaseHistory />
      <CaseStudyExpanded />
      <CompetenceCenter />
      <Livion livion={livion}/>
    </div>
  </>
);

IndexPageTemplate.propTypes = {
  mainpitch: PropTypes.object,
  mainSections: PropTypes.shape({
    mainBlocks: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO
          lang={"it"}
          title={frontmatter.pageTitle}
          description={frontmatter.metaDescription}
          keywords={frontmatter.metaKeywords}
          image={frontmatter.socialPreviewImage}
      />
      <IndexPageTemplate
        mainSections={frontmatter.mainSections}
        mainpitch={frontmatter.mainpitch}
        livion={frontmatter.livion}
      />
      <ContactFormComponent formId={frontmatter.formId} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        ...SeoFragment
        mainpitch {
          text
        }
        mainSections {
          title
          description
          mainBlocks {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageDescription
            url
          }
        }
        livion {
            title
            titleLabel
            description
            link
        }
        formId
      }
    }
  }
`;
