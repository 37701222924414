import React from 'react';
import PropTypes from 'prop-types';

import mainImage from '../../../../static/img/aziende-onlife-cws-mainpitch.svg';
import ContentIndicator from '../../../components/ContentIndicator/ContentIndicator';
import './style/mainpitch.scss';

const Mainpitch = ({ text }) => {
    return (
        <div className="home__mainpitch is-gapless">
            <div className="main-content-container main-content-padding">
                <div className="is-vcentered columns">
                    <div className="column no-padding">
                        <div className="home__mainpitch__title  is-size-3-widescreen">
                            <h1 className="has-text-weight-bold">
                                {text}
                            </h1>
                        </div>
                    </div>
                    <div className="home__mainpitch__image__container column">
                        <img className="home__mainpitch__image" src={mainImage} alt="" />
                    </div>
                </div>
                <ContentIndicator />
            </div>
            
        </div>
    )
}

Mainpitch.propTypes = {
    text: PropTypes.string.isRequired,
}
export default Mainpitch;
